import React from "react";
import PropTypes from "prop-types";
import { Navigation, Footer } from "../../UI";

function Layout({ children, navbarColor }) {
  return (
    <div className="layout">
      <Navigation navbarColor={navbarColor} />
      <div className="layoutBody">{children}</div>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  /**
   * Provide objects/elements to be rendered within the wrapper component.
   */
  children: PropTypes.node.isRequired,
  /**
   * See Navigation component documentation.
   */
  navbarColor: Navigation.propTypes.navbarColor,
};

export { Layout };
