import qs from "qs";

/**
 * Generate contentful compatible URL out of config and asset.
 * @param config
 * @param asset
 * @returns string
 */

export const formatAssetToUrl = (config, asset) => {
  const { width, height, fit, focus, round, format, fl, quality } = config;
  const file = asset.fields.file;
  const baseUrl = `${file.url}`;

  const options = {
    /* If specified width is larger than image width, ignore parameter. */
    w: width,
    h: height,
    fit: fit,
    f: focus,
    r: round,
    fm: format,
    fl: fl,
    q: quality,
  };

  return baseUrl + (qs.stringify(options) ? "?" : "") + qs.stringify(options);
};
