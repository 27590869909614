import React from "react";
//import styles from "./Timeline.module.scss";
import PropTypes from "prop-types";
//import { TimelineElement } from "./TimelineElement";
//import { DummyTimeline } from "../DummyTimeline";

//TODO change the timeline compoment
function Timeline(props) {
  return (
    <>
      {/*        {props.data.map((x, idx) => {
          return (
            <div className="timeline__wrapper" key={idx}>
              <span className="year">{x.year}</span>
              {x.entries.length > 0 && (
                <div className="timeline-container">
                  {x.entries.map((y, idx2) => (
                    <TimelineElement data={y} key={idx2} image={props.image} />
                  ))}
                </div>
              )}
            </div>
          );
        })}*/}
    </>
  );
}

Timeline.propTypes = {
  data: PropTypes.array,
  image: PropTypes.object,
};

export { Timeline };
