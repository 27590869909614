import { useState, useEffect } from "react";

/**
 * React hook to indicate whether the user has scrolled past a certain threshold
 * @param threshold - Threshold after which the hook should return true
 * @returns {boolean}
 */

const useScrollHandler = (threshold = 10) => {
  const [overThreshold, setOverThreshold] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setOverThreshold(window.scrollY >= threshold);
    };

    document.addEventListener("scroll", onScroll);

    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, [overThreshold, setOverThreshold, threshold]);

  return overThreshold;
};

export { useScrollHandler };
