import React, { useState, useEffect } from "react";
import styles from "./Navigation.module.scss";
import { useEscape, useScrollHandler } from "../../Hooks/";
import classNames from "classnames";
import { Backdrop, Logo, DrawerLinkList, Drawer, Button } from "../../UI";
import PropTypes from "prop-types";
import { useRouter } from "next/router";

/* TODO:
1. Coherent .navLink usage (some buttons use navLink, some don't, i.e. text links use it, icon links don't)
2. Componentize more
3. Make menu consist of one JSON structure
4. Iconsets instead of images
5. Combine TabSwitch with the respective tabs -> generalize for other uses?
 */

const linksShop = [
  {
    href: "https://www.thefemalecompany.com/shop/produkt/tamponabo/",
    label: "Bio-Tampons",
  },
  {
    href: "https://www.thefemalecompany.com/shop/produkt/binden-abo/",
    label: "Bio-Binden",
  },
  {
    href: "https://www.thefemalecompany.com/shop/produkt/slipeinlagen-abo/",
    label: "Bio-Slipeinlagen",
  },
  {
    href: "https://www.thefemalecompany.com/shop/produkt/menstruationscup/",
    label: "Menstruationscup",
  },
  { href: "https://www.thefemalecompany.com/mammabox/", label: "MammaBox" },
  {
    href: "https://www.thefemalecompany.com/shop/produkt-kategorie/geschenke/",
    label: "Geschenke",
  },
].map((link) => {
  link.key = `nav-link-${link.href}-${link.label}`;
  return link;
});

const linksAbout = [
  {
    href: "https://www.thefemalecompany.com/ueber-uns/bio/",
    label: "Bio-Baumwolle",
  },
  {
    href: "https://www.thefemalecompany.com/ueber-uns/",
    label: "Unsere Mission",
  },
  {
    href: "https://www.thefemalecompany.com/ueber-uns/padsforgirls/",
    label: "Pads for Girls",
  },
].map((link) => {
  link.key = `nav-link-${link.href}-${link.label}`;
  return link;
});

/**
 * Component which displays navbar tab switcher
 */

function TabSwitch({ tabSwitchHandler, activeTab, className }) {
  return (
    <ul
      className={classNames(styles.navLinkList, styles.tabSwitcher, className)}
    >
      <li className={styles.navLink}>
        <Button
          variant="link"
          onClick={() => {
            tabSwitchHandler(0);
          }}
          className={classNames({
            [styles.active]: activeTab === 0,
          })}
        >
          Shop
        </Button>
      </li>
      <li className={styles.navLink}>
        <Button
          variant="link"
          onClick={() => {
            tabSwitchHandler(1);
          }}
          className={classNames({
            [styles.active]: activeTab === 1,
          })}
        >
          Über uns
        </Button>
      </li>
    </ul>
  );
}

TabSwitch.propTypes = {
  /**
   * Handler used for tab switching
   */
  tabSwitchHandler: PropTypes.func,
  /**
   * Currently active tab
   */
  activeTab: PropTypes.number,
  className: PropTypes.string,
};

/**
 * Navigation component. Includes navbar and nav side drawer.
 */

export function Navigation({ className, navbarColor, ...otherProps }) {
  const defaultActiveTab = 0;
  const noActiveTab = null;

  const [navDrawerOpen, setNavDrawerOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(noActiveTab);
  const { locale } = useRouter().query;

  // Indicate whether we the user has scrolled past a certain distance
  const overThreshold = useScrollHandler(200);

  useEffect(() => {
    // Hide body overflow when drawer is open
    document.body.style.overflow = navDrawerOpen ? "hidden" : "";
    // Unset overflow property on component unmount
    return () => (document.body.style.overflow = "");
  }, [navDrawerOpen]);

  /**
   * Switch tab and open nav drawer
   * @param tab
   */

  const switchTab = (tab) => {
    setActiveTab(tab);
    setNavDrawerOpen(true);
  };

  /**
   * Toggle nav drawer open state.
   */

  const toggleNavDrawer = () => {
    setNavDrawerOpen(!navDrawerOpen);
    /* If the nav drawer is now closed, set to noActiveTab, otherwise to defaultActiveTab */
    /* Note: The ! in the condition is because we want to compare to the previous state */
    setActiveTab(!navDrawerOpen ? defaultActiveTab : noActiveTab);
  };

  /**
   * Close nav drawer.
   */

  const closeNavDrawer = () => {
    setNavDrawerOpen(false);
    setActiveTab(noActiveTab);
  };

  // Close nav drawer when pressing escape
  useEscape(closeNavDrawer);

  return (
    <nav
      className={classNames(styles.navigation, className, {
        [styles.navDrawerOpen]: navDrawerOpen,
        [styles.scrolledOverThreshold]: overThreshold,
      })}
      {...otherProps}
    >
      <Drawer
        className={styles.navDrawer}
        closeHandler={closeNavDrawer}
        isOpen={navDrawerOpen}
        header={
          <TabSwitch tabSwitchHandler={switchTab} activeTab={activeTab} />
        }
      >
        <DrawerLinkList
          links={linksShop}
          className={classNames({ "d-none": activeTab !== 0 })}
        />
        <DrawerLinkList
          links={linksAbout}
          className={classNames({ "d-none": activeTab !== 1 })}
        />
      </Drawer>

      <Backdrop onClick={closeNavDrawer} isVisible={navDrawerOpen} />

      <div className={classNames(styles.navbar, styles[navbarColor])}>
        <Button
          variant="link"
          onClick={toggleNavDrawer}
          className={classNames(
            styles.navbarGroup,
            styles.navbarGroupLeft,
            styles.drawerToggle
          )}
        >
          <svg
            width="32"
            height="40"
            viewBox="0 0 32 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect y="11" width="32" height="2" rx="1" />
            <rect y="19" width="32" height="2" rx="1" />
            <rect y="27" width="32" height="2" rx="1" />
          </svg>
        </Button>

        <TabSwitch
          tabSwitchHandler={switchTab}
          activeTab={activeTab}
          className={classNames(styles.navbarGroup, styles.navbarGroupLeft)}
        />

        <a
          className={styles.logoLink}
          href="https://www.thefemalecompany.com/"
          target="_blank"
        >
          <Logo
            variant="symbol"
            className={classNames(styles.logo, styles.logoSymbol)}
          />
          <Logo
            variant="text"
            className={classNames(styles.logo, styles.logoText)}
          />
        </a>

        <ul
          className={classNames(
            styles.navbarGroup,
            styles.navbarGroupRight,
            styles.navLinkList
          )}
        >
          <li className={styles.navLink}>
            <Button
              variant="link"
              href="/de"
              style={{ fontWeight: locale === "de" ? "bold" : undefined }}
            >
              DE
            </Button>{" "}
            /{" "}
            <Button
              variant="link"
              href="/en"
              style={{ fontWeight: locale === "en" ? "bold" : undefined }}
            >
              EN
            </Button>
          </li>
          <li className={styles.navLink}>
            <Button
              variant="link"
              href="https://drive.google.com/drive/u/1/folders/1I-DrtlQvZXvbZ8wq1dgBlj-73VGUGFS_"
              target="_blank"
            >
              {locale === "de" && "Presse"}
              {locale !== "de" && "Press"}
            </Button>
          </li>
        </ul>
      </div>
    </nav>
  );
}

Navigation.defaultProps = {
  navbarColor: "tomatoRed",
};

Navigation.propTypes = {
  className: PropTypes.string,
  /**
   * Color of the navbar.
   */
  navbarColor: PropTypes.oneOf(["tomatoRed", "white"]),
};
