import React from "react";
import styles from "./Section.module.scss";
import PropTypes from "prop-types";
import {} from "../../UI";
import cx from "classnames";

export function Section({
  children,
  tag: Tag,
  className,
  padding,
  ...otherProps
}) {
  return (
    <Tag
      className={cx(className, styles.section, styles[`section${padding}`])}
      {...otherProps}
    >
      {children}
    </Tag>
  );
}

Section.defaultProps = {
  tag: "section",
  padding: "70",
};

Section.propTypes = {
  /**
   * Section content
   */
  children: PropTypes.node.isRequired,
  /**
   * The html tag to be used for the section
   */
  tag: PropTypes.string,
  /**
   * The section's class name
   */
  className: PropTypes.string,
  /**
   * The amount of vertical padding, specified on mobile.
   */
  padding: PropTypes.oneOf(["0", "70"]),
};
