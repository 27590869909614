import React from "react";
import styles from "./Backdrop.module.scss";
import PropTypes from "prop-types";
import cx from "classnames";

function Backdrop({ onClick, isVisible }) {
  return (
    // As the backdrop does not need a11y features, we disable these eslint rules here
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
    <div
      className={cx(styles.backdrop, { [styles.visible]: isVisible })}
      onClick={onClick}
    />
  );
}

Backdrop.propTypes = {
  /**
   * Provide a function for backdrop's onClick event handler function.
   */
  onClick: PropTypes.func,
  /**
   * Indicate whether the backdrop should be visible
   */
  isVisible: PropTypes.bool,
};

export { Backdrop };
