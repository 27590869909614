import React, { useState, useEffect } from "react";
import { useRouter } from "next/router";
import PropTypes from "prop-types";
import {} from "../UI";
import { Modal, ModalBody } from "reactstrap";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Button } from "../UI";
import CookieConsent, { Cookies } from "react-cookie-consent";

export function OneGirlOneCup({ fields }) {
  const [ageConfirmationOpen, setAgeConfirmationOpen] = useState(false);
  const [redirectAfterConfirmation, setRedirectAfterConfirmation] = useState(
    ""
  );
  const { locale } = useRouter().query;
  const [hasConsented, setHasConstented] = useState(
    Cookies.get("ogoc-cookie-consent")
      ? Cookies.get("ogoc-cookie-consent") === "true"
      : false
  );

  function createGa(i, s, o, g, r, a, m) {
    i["GoogleAnalyticsObject"] = r;
    (i[r] =
      i[r] ||
      function () {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  }

  useEffect(() => {
    if (hasConsented) {
      console.log("Callback has run");
      createGa(
        window,
        document,
        "script",
        "https://www.google-analytics.com/analytics.js",
        "ga"
      );
      ga("create", "UA-109626917-1", "auto");
      ga("send", "pageview");
    }
  }, [hasConsented]);

  const toggleAgeConfirmationOpen = () => {
    setAgeConfirmationOpen(!ageConfirmationOpen);
    setRedirectAfterConfirmation("");
  };

  const confirmAgeThenRedirect = (url) => {
    toggleAgeConfirmationOpen();
    setRedirectAfterConfirmation(url);
    return false;
  };

  return (
    <div className="onegirlonecup">
      <picture className="hero">
        <source
          media="(max-width: 768px)"
          srcSet="/onegirlonecup/one-girl-one-cup-header-mobile.jpg"
        />
        <source
          media="(min-width: 768px)"
          srcSet="/onegirlonecup/one-girl-one-cup-header-desktop.jpg"
        />
        <img
          src="/onegirlonecup/one-girl-one-cup-header-desktop.jpg"
          alt="one girl one cup"
        />
      </picture>

      <div className="marquee">
        <span>
          The most explicit tutorial ever - The most explicit tutorial ever -
          The most explicit tutorial ever - The most explicit tutorial ever -
          The most explicit tutorial ever - The most explicit tutorial ever -
          The most explicit tutorial ever - The most explicit tutorial ever -
          The most explicit tutorial ever -{" "}
        </span>
      </div>

      <div className="content">
        <div className="full">
          <div className="text">
            {documentToReactComponents(fields.firstContentSection)}
          </div>
          <div className="embed">
            <div className="embed-container">
              <iframe
                title="One Girl One Cup Trailer"
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/gViLon2i51I?controls=0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          </div>
        </div>

        <div className="fifthy">
          <div className="text white-button">
            {documentToReactComponents(fields.secondContentSection)}
          </div>
          <div className="box">
            <img className="hero" src="/onegirlonecup/box.jpg" alt="box" />
          </div>
        </div>

        <div className="fifthy">
          <div className="text white-button">
            {documentToReactComponents(fields.thirdContentSection)}
            <a
              onClick={() =>
                confirmAgeThenRedirect(
                  "https://getcheex.com/menstrual-cup-tutorial/"
                )
              }
              href="#"
              rel="nofollow"
            >
              {locale === "de" && "Jetzt anschauen!"}
              {locale !== "de" && "Watch now!"}
            </a>
          </div>
          <div className="img">
            <img
              className="hero"
              src="/onegirlonecup/tutorial.jpg"
              alt="tutorial"
            />
          </div>
        </div>

        <div className="tripple">
          <a
            href="#"
            onClick={() =>
              confirmAgeThenRedirect(
                "https://getcheex.com/menstrual-cup-tutorial/"
              )
            }
            rel="nofollow"
          >
            <img
              className="hero"
              src="/onegirlonecup/tutorial1.jpg"
              alt="tutorial 1"
            />
          </a>
          <a
            href="#"
            onClick={() =>
              confirmAgeThenRedirect(
                "https://getcheex.com/menstrual-cup-tutorial/"
              )
            }
            rel="nofollow"
          >
            <img
              className="hero"
              src="/onegirlonecup/tutorial2.jpg"
              alt="tutorial 2"
            />
          </a>
          <a
            href="#"
            onClick={() =>
              confirmAgeThenRedirect(
                "https://getcheex.com/menstrual-cup-tutorial/"
              )
            }
            rel="nofollow"
          >
            <img
              className="hero"
              src="/onegirlonecup/tutorial3.jpg"
              alt="tutorial 3"
            />
          </a>
        </div>

        <div className="full credits">
          <div className="text">
            {documentToReactComponents(fields.credits)}
          </div>
        </div>

        <div className="full end">
          <h1>#OneGirlOneCup</h1>
        </div>
      </div>

      <CookieConsent
        location="bottom"
        cookieName="ogoc-cookie-consent"
        expires={365}
        containerClasses="ogoc-cookie-consent"
        contentClasses="ogoc-cookie-consent-content"
        buttonWrapperClasses="white-button"
        disableButtonStyles={true}
        buttonText={locale === "de" ? "Akzeptieren" : "Accept"}
        acceptOnScroll={true}
        sameSite="lax"
        onAccept={() => {
          setHasConstented(true);
        }}
      >
        {locale === "de" && (
          <>
            Wir wollen die Periode revolutionieren. Cookies helfen uns dabei,
            deine Bedürfnisse zu verstehen. Wenn du unsere Dienste weiterhin
            nutzt, gehen wir davon aus, dass du der Verwendung von Cookies
            zustimmst. Mehr dazu in unserer{" "}
            <a
              href="https://www.thefemalecompany.com/datenschutz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Datenschutzerklärung
            </a>
            .
          </>
        )}
        {locale !== "de" && (
          <>
            We want to revolutionize the period. Cookies help us understand your
            needs. If you keep using our services, we assume that you consent to
            the usage of cookies. Find out more in our{" "}
            <a
              href="https://www.thefemalecompany.com/datenschutz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Privacy statement
            </a>
            .
          </>
        )}
      </CookieConsent>

      <Modal
        isOpen={ageConfirmationOpen}
        toggle={toggleAgeConfirmationOpen}
        className="ageConfirmationModal"
        centered
      >
        <ModalBody>
          {documentToReactComponents(fields.ageConfirmationText)}
          <Button
            href={redirectAfterConfirmation}
            target="_blank"
            rel="nofollow"
            style={{
              display: "block",
              textAlign: "center",
              width: "100%",
              marginBottom: "10px",
            }}
            outline
          >
            {locale === "de" && "Ich bin über 18 Jahre alt!"}
            {locale !== "de" && "I'm above 18 years old!"}
          </Button>
          <Button
            onClick={toggleAgeConfirmationOpen}
            style={{
              display: "block",
              textAlign: "center",
              width: "100%",
              opacity: 0.5,
              fontSize: "80%",
            }}
            variant="text"
            outline
          >
            {locale === "de" && "Abbrechen"}
            {locale !== "de" && "Back"}
          </Button>
        </ModalBody>
      </Modal>
    </div>
  );
}

OneGirlOneCup.propTypes = {
  fields: PropTypes.object.isRequired,
};
