import React from "react";
import PropTypes from "prop-types";
import { formatAssetToUrl } from "../../../../services/formatAssetToUrl";
import {} from "../../../UI";

const presetWidths = {
  100: [1920, 1600, 1366, 1024, 768, 640],
  75: [1440, 1200, 1025, 768, 576, 480],
  50: [960, 800, 683, 512, 384, 320],
  33: [640, 533, 455, 341, 256, 213],
};

function ResponsiveImage(props) {
  const {
    vw,
    imageWidths,
    asset,
    fit,
    focus,
    round,
    progressive,
    quality,
    ...rest
  } = props;
  const fl = progressive ? "progressive" : undefined;
  const file = asset.fields.file;

  let srcsetWidths;

  if (vw && typeof vw !== "number") {
    throw new Error(
      'The vw prop supplied to Image must be an integer. Use vw={800} instead of vw="800"'
    );
  }

  if (!presetWidths[vw] && !imageWidths) {
    /* No responsive preset is available and no custom widths are specified. */
    throw new Error(
      "Responsive vw is set but no preset is found. Presets are available for vw = 100vw, 75vw, 50vw, 33vw. If you want to use another vw, you have to specify widths via props."
    );
  } else {
    srcsetWidths = imageWidths ? imageWidths : presetWidths[vw];
  }

  if (srcsetWidths[0] > file.details.image.width) {
    /* If first srcsetWidths is larger than original image */
    console.warn(
      `Image warning: The image ${file.fileName} is not large enough to be used as responsive image. Its width ${file.details.image.width} is smaller than ${srcsetWidths[0]}.`
    );
  }

  const baseConfig = {
    width: srcsetWidths[0],
    fit,
    focus,
    round,
    quality,
    fl: file.contentType === "image/jpeg" ? fl : undefined,
  };

  const getResponsiveSrcset = (format) => {
    let responsiveSrcset = [];
    let config = {
      ...baseConfig,
      format,
      fl:
        format && format !== "jpg"
          ? undefined
          : format === "jpg"
          ? fl
          : baseConfig.fl,
    };

    if (srcsetWidths[0] >= file.details.image.width) {
      /* If first srcsetWidths is larger than original image, provide full width image as fallback */
      responsiveSrcset.push(
        `${formatAssetToUrl(config, asset)} ${file.details.image.width}w`
      );
    }

    srcsetWidths.map((srcsetWidth) => {
      if (srcsetWidth < file.details.image.width) {
        let srcsetConfig = {
          ...config,
          width: srcsetWidth,
        };

        /* Current responsive width is not larger than original image. */
        responsiveSrcset.push(
          `${formatAssetToUrl(srcsetConfig, asset)} ${srcsetWidth}w`
        );
      }
    });

    return responsiveSrcset.join(",");
  };

  return (
    <picture>
      <source
        sizes={vw && `${vw}vw`}
        srcSet={getResponsiveSrcset("webp")}
        type="image/webp"
      />
      <source
        sizes={vw && `${vw}vw`}
        srcSet={getResponsiveSrcset()}
        type={file.contentType}
      />
      <img
        src={formatAssetToUrl(baseConfig, asset)}
        alt={asset.fields.title}
        {...rest}
      />
    </picture>
  );
}

ResponsiveImage.defaultProps = {
  progressive: true,
  fit: "fill",
  quality: 65,
};

ResponsiveImage.propTypes = {
  /** The viewport width of the image. */
  vw: PropTypes.number.isRequired,
  /** The widths of the image that should be put into the sourceset. */
  imageWidths: PropTypes.array,
  /** Specify how the image should be fit it the specified sizes. */
  fit: PropTypes.oneOf(["pad", "fill", "scale", "crop", "thumb"]),
  /** Specify the focal point for resizing. */
  focus: PropTypes.oneOf([
    "center",
    "top",
    "right",
    "bottom",
    "top_right",
    "top_left",
    "bottom_right",
    "bottom_left",
    "face",
    "faces",
  ]),
  /** Specify a border radius. */
  round: PropTypes.oneOf([PropTypes.number, "max"]),
  /** Specify whether JPGs should be converted to progressive JPGs. */
  progressive: PropTypes.bool,
  /** Specify the quality of the image. */
  quality: PropTypes.number,
  /** Supply a contentful asset object here. */
  asset: PropTypes.object.isRequired,
};

export { ResponsiveImage };
