import React from "react";
import styles from "./Drawer.module.scss";
import { Button } from "../../UI";
import PropTypes from "prop-types";
import classNames from "classnames";

function Drawer({ isOpen, type, children, closeHandler, header, className }) {
  return (
    <div
      className={classNames(styles.sideDrawer, className, styles[type], {
        [styles.open]: isOpen,
      })}
    >
      <div className={styles.sideDrawerHeader}>
        {header}
        <Button
          variant="link"
          className={classNames(styles.drawerHeaderRight, styles.closeButton)}
          onClick={closeHandler}
        >
          <svg
            width="20"
            height="40"
            viewBox="0 0 20 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect
              width="26.2749"
              height="1.94629"
              rx="0.973143"
              transform="matrix(0.708683 -0.705527 0.708683 0.705527 0.000152588 28.5376)"
              fill="#CC0807"
            />
            <rect
              width="26.2749"
              height="1.94629"
              rx="0.973143"
              transform="matrix(0.708683 0.705527 -0.708683 0.705527 1.3793 10.0892)"
              fill="#CC0807"
            />
          </svg>
        </Button>
      </div>
      <div className={styles.sideDrawerBody}>{children}</div>
    </div>
  );
}

Drawer.propTypes = {
  /** will render elements passed by props such as list, cart etc. */
  children: PropTypes.node.isRequired,
  /**  toggle for opening and closing the drawer */
  isOpen: PropTypes.bool.isRequired,
  /**  left || right based on this prop display side drawer on either lef or right */
  type: PropTypes.oneOf(["left", "right"]),
  /**  pass handler for closing the drawer from within */
  closeHandler: PropTypes.func.isRequired,
  /** pass header */
  header: PropTypes.node,
  className: PropTypes.string,
};

Drawer.defaultProps = {
  type: "left",
};

export { Drawer };
