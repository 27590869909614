import React from "react";
import styles from "./Footer.module.scss";
import { Logo, Section } from "../../UI";
import { Container, Row, Col } from "reactstrap";

function getYear() {
  return new Date().getFullYear();
}
function Footer() {
  const firstColumnLinks = [
    {
      href: "https://www.thefemalecompany.com/ueber-uns/kontakt/",
      label: "Kontakt",
    },
    { href: "https://www.thefemalecompany.com/jobs/", label: "Karriere" },
    {
      href: "https://www.thefemalecompany.com/ueber-uns/presse/",
      label: "Presse",
    },
    { href: "https://www.thefemalecompany.com/dealer/", label: "Filialen" },
  ].map((link) => {
    link.key = `nav-link-${link.href}-${link.label}`;
    return link;
  });

  const secondColumnLinks = [
    { href: "https://www.thefemalecompany.com/agb/", label: "AGB" },
    {
      href: "https://www.thefemalecompany.com/informationen-fuer-kunden/",
      label: "Kundeninformationen",
    },
    {
      href: "https://www.thefemalecompany.com/datenschutz/",
      label: "Datenschutz",
    },
    { href: "https://www.thefemalecompany.com/impressum/", label: "impressum" },
  ].map((link) => {
    link.key = `nav-link-${link.href}-${link.label}`;
    return link;
  });

  return (
    <Section tag="footer" className={styles.footer} id="footer">
      <Container>
        <Row>
          <Col lg="6" md="12">
            <h4 className={styles.footerTitle}>
              I&apos;ve got 99 problems — but <br /> my period ain&apos;t one
            </h4>
          </Col>
          <Col lg="6" md="12">
            <Row>
              <Col lg="6" md="4">
                <h6 className={styles.footerColumnTitle}>Intim</h6>
                {firstColumnLinks.map(({ key, href, label }) => (
                  <ul className={styles.menuItems} key={key}>
                    <li>
                      <a className={styles.menuItem} href={href}>
                        {label}
                      </a>
                    </li>
                  </ul>
                ))}
              </Col>
              <Col lg="6" md="4">
                <h6 className={styles.footerColumnTitle}>Trocken</h6>
                {secondColumnLinks.map(({ key, href, label }) => (
                  <ul className={styles.menuItems} key={key}>
                    <li>
                      <a className={styles.menuItem} href={href}>
                        {label}
                      </a>
                    </li>
                  </ul>
                ))}
              </Col>
            </Row>
          </Col>
        </Row>
        <div className={styles.separatorLine}>{""}</div>
        <Row>
          <Col xs="12" lg="6">
            <Logo color="white" variant="text" className={styles.footerLogo} />
          </Col>
          <Col xs="12" lg="6">
            <div className={styles.disclaimerWrapper}>
              <span className={styles.disclaimer}>
                © {getYear()} THE FEMALE COMPANY - ALL RIGHTS RESERVED.
              </span>
            </div>
          </Col>
        </Row>
      </Container>
    </Section>
  );
}

export { Footer };
