import React from "react";
import styles from "./DrawerLinkList.module.scss";
import PropTypes from "prop-types";
import cx from "classnames";

/* TODO:
Enable client side transitions with next/link
 */

function DrawerLinkList({ links, className }) {
  return (
    <ul className={cx(styles.drawerLinkList, className)}>
      {links.map(({ key, href, label }) => (
        <li key={key} className="animate__animated animate__fadeInLeft">
          <a href={href} target="_blank">
            {label}
          </a>
        </li>
      ))}
    </ul>
  );
}

DrawerLinkList.propTypes = {
  /**
   * Provide a array containing links to be rendered as a list.
   */
  links: PropTypes.array.isRequired,
  className: PropTypes.string,
};

export { DrawerLinkList };
