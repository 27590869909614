import React from "react";
import PropTypes from "prop-types";
import { formatAssetToUrl } from "../../../services/formatAssetToUrl";

function Image(props) {
  const {
    w,
    h,
    asset,
    fit,
    focus,
    round,
    progressive,
    quality,
    ...rest
  } = props;
  const fl = progressive ? "progressive" : undefined;
  const file = asset.fields.file;
  let width = w;
  let height = h;

  if (!width && !height) {
    throw new Error(
      "The Image component should always have either a w or h prop to keep Layout consistent and independent from the uploaded image."
    );
  }

  if (
    (width && typeof width !== "number") ||
    (height && typeof height !== "number")
  ) {
    throw new Error(
      'The w and h prop supplied to Image must be integers. Use w={800} instead of w="800"'
    );
  }

  if (width && width > file.details.image.width) {
    /* Width prop is larger than the source image dimensions. */
    console.warn(
      `Image warning: The requested width for ${file.fileName} of ${width}px is larger than the original images width of ${file.details.image.width}px.`
    );
  }

  if (height && height > file.details.image.height) {
    /* Height prop is larger than the source image dimensions. */
    console.warn(
      `Image warning: The requested height for ${file.fileName} of ${height}px is larger than the original images height of ${file.details.image.height}px.`
    );
  }

  const baseConfig = {
    width,
    height,
    fit,
    focus,
    round,
    quality,
    fl: file.contentType === "image/jpeg" ? fl : undefined,
  };

  const getResolutions = (format) => {
    /* Automatically generate retina resolution versions */

    let config = {
      ...baseConfig,
      format,
      fl:
        format && format !== "jpg"
          ? undefined
          : format === "jpg"
          ? fl
          : baseConfig.fl,
    };

    let resolutions = [`${formatAssetToUrl(config, asset)} 1x`];

    /* Conditionally add 2x and 3x resolutions */
    const pushResolution = (resolution) => {
      if (
        /* Either if both w and h are set and both dont exceed src dimensions, or if one of them is set and doesnt exceed the respective dimension */
        (width &&
          height &&
          width * resolution <= file.details.image.width &&
          height * resolution <= file.details.image.height) ||
        (width && width * resolution <= file.details.image.width) ||
        (height && height * resolution <= file.details.image.height)
      ) {
        resolutions.push(
          `${formatAssetToUrl(
            {
              ...config,
              width: baseConfig.width
                ? baseConfig.width * resolution
                : undefined,
              height: baseConfig.height
                ? baseConfig.height * resolution
                : undefined,
            },
            asset
          )} ${resolution}x`
        );
      }
    };

    pushResolution(2);
    pushResolution(3);

    return resolutions.join(",");
  };

  /**
   * TODO:
   * - Low quality lazy loading (maybe depending on filesize?)
   * - Test conditional resolution loading (conditional based on original width/height)
   * - Test other formats besides supported for conversion
   * - Animated images (e.g. gif)
   * - Test retina resolutions for png etc.
   * - Fixed file type/format
   * - ...
   */

  return (
    <picture>
      <source srcSet={getResolutions("webp")} type="image/webp" />
      <source srcSet={getResolutions()} type={file.contentType} />
      <img
        src={formatAssetToUrl(baseConfig, asset)}
        alt={asset.fields.title}
        {...rest}
      />
    </picture>
  );
}

Image.defaultProps = {
  progressive: true,
  fit: "fill",
  quality: 65,
};

Image.propTypes = {
  /** The width of the desired images. Note: This does not style the image or specify height/width html attributes, its only about resolution. */
  w: PropTypes.number,
  /** The height of the desired images. Note: This does not style the image or specify height/width html attributes, its only about resolution. */
  h: PropTypes.number,
  /** Specify how the image should be fit it the specified sizes. */
  fit: PropTypes.oneOf(["pad", "fill", "scale", "crop", "thumb"]),
  /** Specify the focal point for resizing. */
  focus: PropTypes.oneOf([
    "center",
    "top",
    "right",
    "bottom",
    "top_right",
    "top_left",
    "bottom_right",
    "bottom_left",
    "face",
    "faces",
  ]),
  /** Specify a border radius. */
  round: PropTypes.oneOf([PropTypes.number, "max"]),
  /** Specify whether JPGs should be converted to progressive JPGs. */
  progressive: PropTypes.bool,
  /** Specify the quality of the image. */
  quality: PropTypes.number,
  /** Supply a contentful asset object here. */
  asset: PropTypes.object.isRequired,
};

export { Image };
