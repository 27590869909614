import React from "react";
import Head from "next/head";
import PropTypes from "prop-types";
import { fetchContentfulPage } from "../services/fetchContentfulPage";
import { Layout, Page } from "../components/UI";
import { OneGirlOneCup } from "../components/OneGirlOneCup";

export default function Homepage({ contentful }) {
  const { fields } = contentful;

  return (
    <Page contentful={contentful}>
      <Head>
        <link
          rel="alternate"
          hrefLang="de"
          href="https://onegirlonecup.de/de"
        />
        <link
          rel="alternate"
          hrefLang="en"
          href="https://onegirlonecup.de/en"
        />
        <link
          rel="alternate"
          hrefLang="x-default"
          href="https://onegirlonecup.de/en"
        />
        <meta
          property="og:video"
          content="https://www.youtube.com/v/gViLon2i51I"
        />
      </Head>
      <Layout navbarColor="white">
        <OneGirlOneCup fields={fields} />
      </Layout>
    </Page>
  );
}

Homepage.propTypes = {
  contentful: PropTypes.object.isRequired,
};

export async function getStaticPaths() {
  return {
    paths: [{ params: { locale: "de" } }, { params: { locale: "en" } }],
    fallback: false,
  };
}

export async function getStaticProps({ params }) {
  const contentful = await fetchContentfulPage(
    "oneGirlOneCupPage",
    params.locale
  );

  return {
    props: {
      contentful,
    },
  };
}
