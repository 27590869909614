import React from "react";
import PropTypes from "prop-types";
import {} from "../../UI";
import Router from "next/router";
import Head from "next/head";
import { formatAssetToUrl } from "../../../services/formatAssetToUrl";

function Page({ contentful: { fields }, ...props }) {
  const { seo } = fields;

  const title = seo.fields.title
    ? seo.fields.title
    : fields.title
    ? fields.title
    : /* TODO: Fallback in case no title is specified? */ undefined;

  const page = {
    title: title,
    disableTitleTemplate: seo.fields.disableTitleTemplate,
    description: seo.fields.description ? seo.fields.description : undefined,
    openGraphType: seo.fields.openGraphType
      ? seo.fields.openGraphType
      : undefined,
    openGraphTitle: seo.fields.openGraphTitle
      ? seo.fields.openGraphTitle
      : title,
    openGraphDescription: seo.fields.openGraphDescription
      ? seo.fields.openGraphDescription
      : seo.fields.description
      ? seo.fields.description
      : undefined,
    openGraphImage: seo.fields.openGraphImage
      ? seo.fields.openGraphImage
      : /* TODO: Maybe fallback to first asset on page */
        undefined,
  };

  /* TODO: This is not failsafe. Handle if seo undefined. */

  return (
    <>
      <Head>
        <title key="title">
          {page.title}
          {!page.disableTitleTemplate ? " — The Female Company" : undefined}
        </title>
        {page.description && (
          <meta
            key="description"
            name="description"
            content={page.description}
          />
        )}
        {page.openGraphType && (
          <meta key="og:type" property="og:type" content={page.openGraphType} />
        )}
        {page.openGraphTitle && (
          <meta
            key="og:title"
            property="og:title"
            content={page.openGraphTitle}
          />
        )}
        {page.openGraphDescription && (
          <meta
            key="og:description"
            property="og:description"
            content={page.openGraphDescription}
          />
        )}
        {page.openGraphImage && (
          <>
            <meta
              property="og:image"
              content={formatAssetToUrl(
                {
                  quality: 70,
                  width: 1080,
                  height: 1080,
                  format: "jpg",
                  fl: "progressive",
                  fit: "fill",
                },
                page.openGraphImage
              )}
            />
            <meta property="og:image:type" content="image/jpeg" />
            <meta property="og:image:width" content="1080" />
            <meta property="og:image:height" content="1080" />
            <meta
              property="og:image:alt"
              content={page.openGraphImage.fields.title}
            />
          </>
        )}
      </Head>
      {props.children}
    </>
  );
}

Page.propTypes = {
  /**
   * Provide pages to be rendered within the wrapper component.
   */
  children: PropTypes.node.isRequired,
  /**
   * Provide a contentful API response object.
   */
  contentful: PropTypes.object.isRequired,
};

export { Page };
