import React, { forwardRef } from "react";
import styles from "./Button.module.scss";
import PropTypes from "prop-types";
import cx from "classnames";

/* TODO:
Implement icon variant?
 */

export const Button = forwardRef(ButtonWithoutRef);

function ButtonWithoutRef(
  {
    variant,
    href,
    onClick,
    tag,
    children,
    outline,
    responsive,
    className,
    ...otherProps
  },
  ref
) {
  // Tries to intelligently determine proper tag
  // 1. If `tag` prop is given, use the prop
  // 2. If href is set, use anchor
  // 3. Else use button
  const Tag = tag ? tag : href ? "a" : "button";

  return (
    <Tag
      className={cx(
        variant === "link" ? styles.btnLink : styles.btn,
        {
          [styles.btn]: variant !== "link",
          [styles.btnLink]: variant === "link",
          [styles[
            `btn${
              /* Make first of variant letter uppercase. `variant="primary"` results in `btnPrimary` */
              variant ? variant.charAt(0).toUpperCase() + variant.slice(1) : ""
            }`
          ]]: !!variant && variant !== "link",
          [styles.btnOutline]: outline && variant !== "link",
          [styles.btnResponsive]: responsive && variant !== "link",
        },
        className
      )}
      href={href}
      onClick={onClick}
      ref={ref}
      {...otherProps}
    >
      {children}
    </Tag>
  );
}

ButtonWithoutRef.propTypes = {
  /**
   * Button variant
   */
  variant: PropTypes.oneOf([
    /**
     * Styles the button like a regular link.
     */
    "link",
    "primary",
    "secondary",
    "text",
  ]),
  /**
   * Indicate whether the button should have an outline. Not available for `link` variant.
   */
  outline: PropTypes.bool,
  /**
   * Href used for anchor tag. If set, the button will be an anchor tag
   */
  href: PropTypes.string,
  /**
   * onClick handler.
   */
  onClick: PropTypes.func,
  /**
   * Tag that the button should use. Overwrites default behavior.
   */
  tag: PropTypes.string,
  /**
   * Indicate whether the the button should be responsive. Not available for `link` variant.
   */
  responsive: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  variant: "primary",
  outline: false,
  responsive: true,
};
